@import url('https://fonts.googleapis.com/css?family=Jersey+10&effect=3d');
.jersey-10-regular {
    font-family: "Jersey 10", sans-serif;
    font-weight: 400;
    font-style: normal;
}
/* @import url('https://fonts.googleapis.com/css?family=Whisper&effect=3d');
.whisper-regular {
    font-family: "Whisper", cursive;
    font-weight: 400;
    font-style: normal;
} */

.home {
    &.page {
        height: 100%;
        background: linear-gradient(90deg, #5faa19 50%, #65209d 50%);
        display: flex;
        flex-direction: column;
    }

    &.container {
        display: flex;
        flex: 1;
        background-color: #d0d6d7;
        max-width: 900px;
    }

    &.title {
        font-size: 200px;
    }
}
