.login {
    background-color: rgb(43, 45, 49);
    font-family: 'gg sans';
    color: white;
    
    &.page {
        height: 100%;
        /* background: #d0d6d7; */
        display: flex;
        flex-direction: column;
    }
}

.center-text {
    text-align: center;
}
