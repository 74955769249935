@font-face {
    font-family: 'gg sans';
    src: local('gg sans'), url(../assets/gg\ sans\ Regular.ttf) format('truetype');
}

.quote {
    &.card {
        background-color: rgb(43, 45, 49);
        border-color: rgb(43, 45, 49);
        font-family: 'gg sans';
        
        .card-header {
            background-color: rgb(43, 45, 49);
            color: #ffffff;
        }

        .card-body {
            background-color: rgb(49, 51, 56);
            color: rgb(219, 222, 225);
        }

        .card-footer {
            background-color: rgb(43, 45, 49);
            color: #ffffff;
        }
    }
}
